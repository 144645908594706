<script setup lang="ts">
import Button from '../design-system/button.vue';
import {
  siteModalsStrings,
  AccountModalsStrings,
  type Modals,
  type AccountModals,
} from '~/interfaces/dto/general/modals';
import Drawer from 'primevue/drawer';

const siteModals = siteModalsStrings;
const accountModals = AccountModalsStrings;

const active = ref<boolean>(false);

const site = useSiteStore();
const auth = useAuthStore();

function openSiteModal(modal: Modals) {
  site.activateModal(modal);
}

function openAccModal(modal: AccountModals) {
  auth.setAccountModalPage(modal);
  auth.toggleAccountModal(true);
}

function closeAllModals() {
  site.deactivateModal();
  auth.toggleAccountModal(false);
}

function emergencyModalClose(event: KeyboardEvent) {
  if (event.shiftKey && event.key === 'Z') closeAllModals();
}

onMounted(() => {
  window.addEventListener('keydown', emergencyModalClose);
});

onUnmounted(() => {
  window.removeEventListener('keydown', emergencyModalClose);
});
</script>
<template>
  <div class="flex gap-2">
    <Button
      class="font-normal std-border w-full"
      type="tertiary"
      padding="md"
      rounding="md"
      @click="active = true"
    >
      Toggle Modals
    </Button>
    <Drawer
      v-model:visible="active"
      header="Modals"
      position="bottom"
      class="text-base p-4 overflow-auto"
    >
      <template #header>
        <div class="flex gap-3" style="z-index: 100">
          <p class="text-base-priority text-lg font-bold">Modals</p>

          <Button
            type="neutral"
            padding="wide-short"
            rounding="md"
            @click="closeAllModals()"
          >
            Clear Modals
          </Button>
          <p class="text-xs leading-8">
            (Press Shift+Z if Modals get stuck when opening to close)
          </p>
        </div>
      </template>
      <div
        class="text-base grid grid-cols-5 gap-2 max-h-[65vh] overflow-scroll"
      >
        <div class="bg-primary-layer p-2 rounded-lg col-span-3">
          <div class="font-bold">Site Modals</div>
          <hr class="mb-4" />
          <div class="grid grid-cols-3 grid-flow-col grid-rows-9">
            <div
              v-for="modal in siteModals"
              :key="'site-' + modal"
              class="flex justify-between mb-1"
            >
              <Button
                class="font-normal std-border"
                :type="site.currentModal === modal ? 'primary' : 'tertiary'"
                padding="md"
                rounding="md"
                @click="openSiteModal(modal)"
              >
                {{ modal }}
              </Button>
            </div>
          </div>
        </div>
        <div class="bg-primary-layer p-2 rounded-lg col-span-2">
          <div class="font-bold">Account Modals</div>
          <hr class="mb-4" />
          <div class="grid grid-cols-2 grid-flow-col grid-rows-9">
            <div
              v-for="modal in accountModals"
              :key="'acc-' + modal"
              class="flex justify-between mb-1"
            >
              <Button
                class="font-normal std-border"
                :type="auth.accountModalPage === modal ? 'primary' : 'tertiary'"
                padding="md"
                rounding="md"
                @click="openAccModal(modal)"
              >
                {{ modal }}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  </div>
</template>
